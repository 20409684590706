import styles from './Slider.module.scss';
import headerStyles from '@/components/organisms/Header/Header.module.scss';
import borders from '@/styles/modules/BorderColors.module.scss';

import {
  Fragment,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import { findRegionByCountryCode } from '@/utilities/helpers/Region';
import { register } from 'swiper/element/bundle';
import { type SwiperOptions } from 'swiper/types';
import { useCountry } from '@/utilities/contexts/CountryContext';
import Button from '@/components/atoms/Button';
import Buttons from '@/components/molecules/Buttons';
import Chip from '@/components/atoms/Chip';
import cn from 'classnames';
import Column from '@/components/atoms/Column/Column';
import Container from '@/components/atoms/Container/Container';
import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/image';
import Row from '@/components/atoms/Row';
import SliderPagination from './SliderPagination';
import Swiper from 'swiper';
import typeSlider from '@/utilities/types/Slider';
import Typography from '@/components/atoms/Typography';

interface SliderSwiperProps {
  addEventListener(arg0: string, arg1: (e: any) => void): unknown;
  removeEventListener(arg0: string, arg1: (e: any) => void): unknown;
  initialize: () => void;
  swiper: Swiper;
  ref?: RefObject<SliderSwiperProps>;
}

declare global {
  namespace JSX {
    interface SliderSwiperContainer {
      children?: ReactNode;
      class?: string;
      init?: 'true' | 'false';
      ref?: RefObject<SliderSwiperProps>;
    }
    interface IntrinsicElements {
      'swiper-container': SwiperContainer;
      'swiper-slide': any;
    }
  }
}

const AUTOPLAY_DELAY = 5000;

interface SliderProps {
  content: typeSlider;
}

const Slider = (props: SliderProps) => {
  const {
    content: { content: intro, slides, mobileImage },
  } = props;

  const country = useCountry();
  const region = findRegionByCountryCode(country);

  const [isInitiated, setIsInitiated] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const swiperContainer = useRef<SliderSwiperProps | null>(null);

  const slidesRegional =
    (slides &&
      slides.filter(
        (slide) =>
          slide.region?.includes('all') || slide.region?.includes(region),
      )) ||
    slides;

  const sortedSlides = slidesRegional.slice().sort((a, b) => {
    if (!a.mainSlide || !b.mainSlide) {
      return 0;
    }
    if (a.mainSlide === region && b.mainSlide !== region) {
      return -1;
    } else if (a.mainSlide !== region && b.mainSlide === region) {
      return 1;
    }
    return 0;
  });

  const goToSlide = (index: number) => {
    if (index < 0 || index > sortedSlides.length) return;
    if (swiperContainer.current) {
      swiperContainer.current.swiper.slideTo(index);
    }
  };

  const initSlider = () => {
    register();

    const params: SwiperOptions = {
      slidesPerView: 1,
    };

    if (sortedSlides.length > 1) {
      params.autoplay = {
        delay: AUTOPLAY_DELAY,
      };
    }

    if (swiperContainer.current && sortedSlides.length > 0) {
      Object.assign(swiperContainer.current, params);
      swiperContainer.current.initialize();
      setIsInitiated(true);
    }
  };

  useEffect(() => {
    initSlider();

    const handleSlideChange = () => {
      if (swiperContainer.current) {
        setActiveSlide(swiperContainer.current.swiper.activeIndex);
      }
    };

    if (swiperContainer.current) {
      swiperContainer.current.addEventListener(
        'swiperslidechange',
        handleSlideChange,
      );
    }

    return () => {
      if (swiperContainer.current) {
        swiperContainer.current.removeEventListener(
          'swiperslidechange',
          handleSlideChange,
        );
      }
    };
  }, [swiperContainer.current]);

  return (
    <section className={styles.root}>
      <Container key={'intro'}>
        <Row justify="center">
          <Column xs={12} md={12} lg={10} className={styles.introWrapper}>
            <div className={styles.intro}>
              {intro.map((item, index: number) => (
                <Fragment key={index}>
                  {item.chip && (
                    <Chip
                      className={cn(
                        item?.color && borders[item.color],
                        styles.chip,
                      )}
                    >
                      {item.chip}
                    </Chip>
                  )}
                  {item?.title && (
                    <Typography
                      xs={6}
                      md={7}
                      lg={9}
                      tag="h1"
                      family="poppins"
                      className={styles.title}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.title),
                        }}
                      />
                    </Typography>
                  )}
                  {item?.editor && (
                    <Typography xs={3} md={4} tag="div" className={styles.text}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.editor),
                        }}
                      />
                    </Typography>
                  )}
                  {item.links && (
                    <Buttons buttons={item?.links} className={styles.links} />
                  )}
                </Fragment>
              ))}
            </div>
          </Column>
        </Row>
      </Container>

      <div className={cn(headerStyles.container)}>
        {/* BEGIN SLIDER */}
        <div
          className={cn(isInitiated && styles.initiated, styles.main)}
          key={'slider'}
        >
          {sortedSlides && (
            <swiper-container ref={swiperContainer} init="false">
              {sortedSlides.map((slide, index) => (
                <Fragment key={index}>
                  {slide.image && (
                    <swiper-slide key={index} regions={[]}>
                      <Image
                        alt={slide.image.altText}
                        className={styles.image}
                        height={680}
                        src={slide.image.mediaItemUrl}
                        width={1728}
                        priority={index === 1}
                      />
                      {slide.features &&
                        slide.features.map((feature, featureIndex: number) => (
                          <Fragment key={featureIndex}>
                            <style jsx>{`
                                div {
                                  ${
                                    feature.positioning?.desktop?.left ||
                                    feature.positioning?.desktop?.right ||
                                    feature.positioning?.desktop?.top
                                      ? `
                                          ${
                                            feature.positioning?.desktop?.left
                                              ? `left: ${feature.positioning.desktop.left}rem;`
                                              : ''
                                          }
                                          ${
                                            feature.positioning?.desktop?.top
                                              ? `top: ${feature.positioning.desktop.top}rem;`
                                              : ''
                                          }
                                          ${
                                            feature.positioning?.desktop?.right
                                              ? `right: ${feature.positioning.desktop.right}rem;`
                                              : ''
                                          }
                                      `
                                      : ''
                                  }
                            `}</style>
                            <div
                              className={cn(
                                styles.feature,
                                feature.imageLocation === 'above' &&
                                  styles.imageAbove,
                                `feature${featureIndex + 1}`,
                              )}
                            >
                              {feature.button && feature.button.url && (
                                <Button
                                  style="primary"
                                  size="large"
                                  href={feature.button.url}
                                  className={`${
                                    feature.button.icon &&
                                    feature.button.icon.mediaItemUrl &&
                                    feature.button.iconLocation === 'right'
                                      ? styles.iconRight
                                      : ''
                                  }`}
                                >
                                  {feature.button.icon &&
                                    feature.button.icon.mediaItemUrl && (
                                      <i>
                                        <Image
                                          src={feature.button.icon.mediaItemUrl}
                                          alt={feature.button.icon.altText}
                                          width={
                                            feature.button.icon.mediaDetails
                                              .width || 15
                                          }
                                          height={
                                            feature.button.icon.mediaDetails
                                              .height || 15
                                          }
                                          priority={index === 1}
                                        />
                                      </i>
                                    )}
                                  {feature.button.text}
                                </Button>
                              )}
                              {feature.image && feature.image.mediaItemUrl && (
                                <Image
                                  src={feature.image.mediaItemUrl}
                                  alt={feature.image.altText || 'feature'}
                                  width={
                                    feature.image.mediaDetails.width || 300
                                  }
                                  height={
                                    feature.image.mediaDetails.height || 450
                                  }
                                  priority={index === 1}
                                />
                              )}
                            </div>
                          </Fragment>
                        ))}
                    </swiper-slide>
                  )}
                </Fragment>
              ))}
            </swiper-container>
          )}

          <SliderPagination
            activeSlide={activeSlide}
            setActiveSlide={goToSlide}
            slides={sortedSlides}
          />
        </div>
        {/* END SLIDER */}

        {/* BEGIN MOBILE IMAGE */}
        <div className={styles.mobile}>
          {mobileImage && mobileImage.mediaItemUrl && mobileImage.altText && (
            <Image
              priority // no lazy load for mobile slide
              src={mobileImage.mediaItemUrl}
              alt={mobileImage.altText}
              width={350}
              height={350}
            />
          )}
        </div>
        {/* END MOBILE IMAGE */}
      </div>
    </section>
  );
};

export default Slider;
